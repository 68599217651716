export const colors = {}

export const bp = {
  s: 480,
  m: 768,
  l: 1200,
  xl: 1400,
}

export const mq = {
  gte: (size) => `(min-width: ${bp[size]}px)`,
  lt: (size) => `(max-width: ${bp[size] - 1}px)`,
}

export const site = {
  width: 1360,
  gutter: {
    s: 20,
    l: 40,
  },
  gridGutter: 20,
}
